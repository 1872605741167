.Home {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.bg {
  position: absolute;
  z-index: -1;
  top: -70px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.carrotsBalance {
  display: flex;
  gap: 20px;
  padding: 0 30px;
}

.hareBalance {
  margin-top: 25px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.balanceTitle {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #AF5E2B;
  font-family: 'Marvin', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.balanceValue {
  position: relative;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #A15424;
  font-family: "LilitaOne", sans-serif;
  font-size: 42px;
  font-weight: 400;
  letter-spacing: 0;
}

.harvestContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.harvestSubContainer {
  position: relative;
  width: 340px;
  height: 70px;
  
  border-radius: 12px;
  border: 3px solid #984C1F;
  background: #A15424;
  box-shadow: 0px 4px 6px 0px rgba(177, 97, 46, 0.10) inset;

  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 10px;
  gap: 10px;
}

.harvestInfo {
  width: 100%;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 3px;
}

.harvestText {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.4px;
}

.harvestMeter {
  position: relative;

  width: 100%;
  height: 18px;
  border-radius: 12px;
  border: 3px solid #984C1F;  
  background: linear-gradient(0deg, #803C1A 0%, #803C1A 100%), #FFCD00;
  box-shadow: 0px 4px 6px 0px rgba(177, 97, 46, 0.10) inset;
  display: flex;
  justify-content: start;
  align-items: center;
}

.harvestTime {
  position: absolute;
  width: 100%;
  text-align: right;
  padding-right: 13px;
  
  color: #D9C4BA;
  font-family: 'Montserrat', sans-serif;
  font-size: 9px;
  font-weight: 500;
  line-height: 14px; /* 175% */
}

.harvestStat {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px; /* 116.667% */
  margin-left: 3px;
}

.harvestPercent {
  width: 50%;
  height: 12px;
  border-radius: 12px;
  
  background: #9EC604;
}

.harvestButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  width: 90px;
  height: 32px;
  border-radius: 8px;
  background: #9EC604;
  box-shadow: 0px -3px 4.472px 0px #64A200 inset, 0px 3px 5.656px 0px rgba(93, 48, 19, 0.60);

  padding: 0 8px;
  text-shadow: 0px 1px 0px #0000007a;
  -webkit-text-stroke-width: .5px;
  -webkit-text-stroke-color: #803C1A;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 0.4px;
}

.fontBack {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-text-stroke-width: 10px;
  -webkit-text-stroke-color: #FFBC00;

  font-family: "LilitaOne", sans-serif;
  font-size: 42px;
  font-weight: 400;
  letter-spacing: 0;
}

.feedStaminaContainer {
  margin-bottom: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.feedContainer {

}

.timeToFeed {
  display: flex;
  justify-content: end;
  align-items: end;

  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.4px;
}

.starveContainer {
  position: relative;
  width: 320px;
  height: 49px;
  
  border-radius: 12px;
  border: 3px solid #984C1F;
  background: #A15424;
  box-shadow: 0px 4px 6px 0px rgba(177, 97, 46, 0.10) inset;

  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 30px;
  gap: 10px;
}



.decor {

}

.starveCarrot {

}

.staminaContainer {
  margin-top: 20px;
  position: relative;
  width: 260px;
  height: 13px;
  border-radius: 12px;
  border: 2px solid #FFCC7C;  
  background: linear-gradient(0deg, #803C1A 0%, #803C1A 100%), #FFCD00;
  box-shadow: 0px 4px 6px 0px rgba(177, 97, 46, 0.10) inset;
  display: flex;
  justify-content: start;
  align-items: center;
}

.stamina {
  width: 255px;
  height: 11px;
  border-radius: 0px 12px 12px 0px;
  margin-left: 10px;
  background: #F7A313;
}

.icon {

}

.vintik {
  width: 12px;
  height: 12px;
}

.starveCarrotImg {
  position: absolute;
  left: -20px;
  top: -25px;
}

.staminaIcon {
  position: absolute;
  top: -7px;
  left: -12px;
  width: 24px;
  height: 24px;
}

.rabbitContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  margin-bottom: 10px;
}

.Rabbit {
  position: relative;
  z-index: 0;
  height: 231px;
  margin-left: 30px;
  @media (max-height: 720px) {
    height: 160px;
  }
}

.showel {
  width: 16px;
  height: 20px;
}

.feedTime {
  margin-left: 5px;
  color: rgba(255, 255, 255, 0.50);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
  letter-spacing: -0.4px;
}

.timeIcon {
  width: 16px;
  height: 16px;
  margin-right: 3px;
}

.char {
  position: relative;
}

.sun {
  position: absolute;
  left: -205%;
  top: -195%;
  width: 500%; /* Ширина изображения, можно настроить */
  height: auto; /* Высота автоматически пропорциональна */
  z-index: -1; /* Ставим перед фоном, но за всеми другими элементами */
  opacity: 0.5; /* Опционально: полупрозрачный для улучшения видимости других элементов */
  animation: rotate 60s linear infinite; /* Анимация вращения */
  @media (max-height: 720px) {
    top: -300%;
  }
}

/* Определение анимации вращения */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.feedbg {
  position: relative;
  width: 260px;
  height: 37px;
  border-radius: 12px;
  border: 3px solid #984C1F;

  background: #803C1A;
  
  box-shadow: 0px 4px 6px 0px rgba(177, 97, 46, 0.10) inset;
  
}

.starveMeter {
  position: absolute;
  left: 0;
  top: 0;
  width: 260px;
  height: 32px;
  border-radius: 12px;
  
  background: #76B733;
  
  box-shadow: 0px 2px 6px 0px #9DC97E inset;
  
}