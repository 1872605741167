@font-face {
  font-family: 'LilitaOne';
  src: url('./assets/fonts/lilitaonerus-webfont.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'Marvin';
  src: url('./assets/fonts/marvin.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBold.woff2') format('woff2');
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'LilitaOne', sans-serif;
  color: white;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

html, body, #root {
  height: 100%;
  overflow: hidden;
}