.overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: end;
  backdrop-filter: blur(2px);
}

.modal {
  position: relative;
  width: 346px;
  height: 236px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cross {
  z-index: 1;
  position: absolute;
  top: 18px;
  right: 23px;
  width: 40px;
  height: 40px;
}

.close {
  width: 40px;
  height: 40px;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 346px;
  height:236px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  z-index: 1;
  position: relative;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #A15424;
  font-family: "LilitaOne", sans-serif;
  font-size: 42px;
  font-weight: 400;
  letter-spacing: 0;
}

.fontBack {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-text-stroke-width: 10px;
  -webkit-text-stroke-color: #FFBC00;

  font-family: "LilitaOne", sans-serif;
  font-size: 42px;
  font-weight: 400;
  letter-spacing: 0;
}

.message {
  position: relative;
  margin-top: 15px;
  text-shadow: 0px 1px 0px #0000007e;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #AA5929;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 107.692% */
  letter-spacing: 0.4px;
}

.button {
  position: relative;
  margin-top: 30px;
  width: 170px;
  height: 36px;
  border-radius: 12px;
  background: #9EC604;
  box-shadow: 0px 3px 5.656px 0px rgba(93, 48, 19, 0.60), 0px -3px 4.472px 0px #64A200 inset;

  display: flex;
  justify-content: center;
  align-items: center;

  text-shadow: 0px 1px 0px #0000007d;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #803C1A;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
}