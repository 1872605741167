.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Фиксированное позиционирование для покрытия всего экрана */
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #fff; /* Белый фон для фоллбека */
  z-index: 1000; /* Обеспечивает, что экран загрузки будет поверх остальных элементов */
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../../assets/images/loading-bg.jpg') no-repeat center center/cover; /* Фоновое изображение */
  z-index: 1;
}

.content {
  position: absolute;
  top: 80%; /* Располагаем прогресс-бар на 70% от верхней части экрана */
  transform: translateY(-50%); /* Центрируем прогресс-бар по вертикали относительно заданного top */
  z-index: 2;
  text-align: center;
}

.progressBar {
  width: 100px; /* Настройте ширину по своему вкусу */
  height: 30px; /* Настройте высоту по своему вкусу */
  background: rgba(0, 0, 0, 0.2); /* Фон прогресс-бара */
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.progressBar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0; /* Начальная ширина прогресс-бара */
  height: 100%;
  background: #4caf50; /* Цвет прогресс-бара */
  animation: loading 2s linear forwards; /* Анимация прогресса */
}

@keyframes loading {
  0% { width: 0%; }
  100% { width: 100%; }
}