.overlay {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  z-index: 10001;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Onboarding {
  position: absolute;

  display: flex;
  flex-direction: column;
  width: 280px;
  padding: 18px 20px;
  justify-content: center;
  align-items: start;
  gap: 6px;
  border-radius: 12px;
  border: 3px solid #984C1F;
  background: #E59749;

  font-family: 'Montserrat', sans-serif;
}

.title {
  font-size: 18px;
  font-weight: 900;
}

.hr {
  width: 100%;
  border: 1px dashed #803C1A;
}

.content {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress {
  font-size: 12px;
  font-weight: 500;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 28px;
  background-color: #9ec604;
  border-radius: 8px;
  box-shadow: 0px -3px 4.472px 0px #64A200 inset;
  -webkit-text-stroke-width: .5px;
  -webkit-text-stroke-color: #803C1A;
  font-family: "LilitaOne", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.triangle {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: 0;
}

.triangleIcon {
  position: absolute;
  bottom: -10px;
}