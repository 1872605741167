.NoPhone {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.buttons {
  position: absolute;
  bottom: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.buttonsTop {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.buttonsBot {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px
}

.telegram, .twitter, .discord, .website {
  width: 160px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  text-shadow: 0px 1px 0px #00000072;
  -webkit-text-stroke-width: .8px;
  -webkit-text-stroke-color: #803C1A;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.4px;
}

.telegram {
  background-color: #23A4FF;
  box-shadow: 0px -3px 4.472px 0px #1177c0 inset;
}

.twitter {
  background-color: #FFCD00;
  box-shadow: 0px -3px 4.472px 0px #ba9500 inset;
}

.discord {
  background-color: #B4B4B4;
  box-shadow: 0px -3px 4.472px 0px #656565 inset;
}

.website {
  background-color: #9EC604;
  box-shadow: 0px -3px 4.472px 0px #64A200 inset;
}