.Menu {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
}

.bg {
  position: absolute;
  width: 100%;
  height: 120px;
  object-fit: cover;
  align-self: flex-end;
}

.MenuItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 20px;
}

.part {
  width: 33%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carrotContainer {
  position: absolute;
  bottom: 80px;
  left: calc(50% - 70px);
  width: 140px;
  height: 60px;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carrotButton {
  position: relative;
  width: 58px;
  height: 58px;
}

.switchL {
  position: relative;

  width: 30px;
  height: 30px;
}

.switchR {
  position: relative;

  width: 30px;
  height: 30px;
  transform: scaleX(-1)
}

.oopsCarrot {
  width: 16px;
  margin-right: 6px;
}