.TaskItem {
  z-index: 1;
  width: 100%;
  height: 90px;
  border-radius: 12px;
  border: 3px solid #984C1F;
    
  background: #AB5B2A;
  
  box-shadow: 0px 0px 50.1px 0px #AA5929 inset;

  padding: 10px 13px 10px 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
}

.taskContainer {
  width: 100%;
  height: 70px;
  padding: 12px 15px;

  border-radius: 12px;
  border: 2px solid #984C1F;
  background: #F6B867;
  box-shadow: -4px 0px 1px 0px #AA5929 inset, 4px 0px 1px 0px #AA5929 inset, 0px 4px 1px 0px #AA5929 inset, 0px -4px 1px 0px #AA5929 inset;

  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
}

.imgContainer {
  width: 45px;
  height: 45px;
  background-color: #E59749;
  border: 2px solid #FFCC7C;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  height: 43px;
  width: 43px;
  object-fit: contain;
  border-radius: 10px;
}

.taskInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 3px;
}

.questTitle {
  text-shadow: 0px 1px 0px #00000071;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #803C1A;
  font-family: "LilitaOne", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.questReward {
  -webkit-text-stroke-width: 0.8px;
  -webkit-text-stroke-color: #AA5929;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rewardInfo {
  margin-top: 3px;
}

.button {
  width: 34px;
  height: 34px;
}

.bimg {
  width: 34px;
  height: 34px;
  transform: scaleX(-1);
}

.carrot {
  margin-right: 3px;
  width: 16px;
}

.carrotImg {
  width: 16px;
  height: 18px;
  transform: scaleX(-1);
}