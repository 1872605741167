.Invite {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;

  background-color: #593218;
}

.copyMessage {
  z-index: 1;
  position: absolute;
  top: 20px;
}

.cross {
  z-index: 1;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
}

.close {
  width: 40px;
  height: 40px;
}

.earnBg {
  position: absolute;
  top: 0;
  width: 100%;
}

.tasksContainer {
  position: relative;
  width: 92%;
  height: 70%;
  padding-top: 70px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 12px 12px 0px 0px;
  background-color: #B36430;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.taskBg {
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.inviteContainer {
  position: absolute;
  top: -60px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 328px;
  height: 100%;
  padding: 16px;
  align-items: center;
  gap: 10px;

  border-radius: 12px;
  background: #803C1A;
  box-shadow: -4px 0px 1px 0px #AA5929 inset, 4px 0px 1px 0px #AA5929 inset, 0px 4px 1px 0px #AA5929 inset, 0px -4px 1px 0px #AA5929 inset;
}

.hr {
  width: 100%;
  border: 1px dashed #F6B867;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 8px;
}

.mainText {
  text-shadow: 0px 1px 0px #0000007f;
  -webkit-text-stroke-width: .5px;
  -webkit-text-stroke-color: #803C1A;
  font-family: 'Marvin', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 17px; /* 113.333% */
  text-transform: uppercase;
}

.subText {
  font-family: 'Montserrat', sans-serif;
  text-shadow: 0px 2px 0px #0000007f;
  font-size: 12px;
  font-weight: 600;
  line-height: 13px; /* 108.333% */
}

.color {
  color: #FFCD00;
}

.inviteSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
}

.inviteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 123px;
  height: 32px;
  border-radius: 8px;
  background: #9EC604;
  box-shadow: 0px 2px 6px 0px #9DC97E inset;
  padding: 0 15px;
  
  text-align: center;
  text-shadow: 0px 1px 0px #00000076;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #803C1A;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.inviteLink {
  display: flex;
  width: 160px;
  height: 36px;
  padding: 7px 5px;
  justify-content: center;
  align-items: center;
  gap: 15px;

  border-radius: 12px;
  border: 2px solid #984C1F;
  opacity: var(--sds-size-stroke-border);
  background: #803C1A;
  box-shadow: 0px 0px 6px 0px rgba(177, 97, 46, 0.20) inset;
}

.link {
  font-size: 10px;
  font-weight: 400;
  letter-spacing: -0.4px;
}

.copyImg {
  width: 12px;
  height: 12px;
}

.block {
  width: 100%;
  padding: 13px;
  border-radius: 12px;
  border: 2px solid #984C1F;
  opacity: var(--sds-size-stroke-border);
  background: #F6B867;
  box-shadow: -4px 0px 1px 0px #AA5929 inset, 4px 0px 1px 0px #AA5929 inset, 0px 4px 1px 0px #AA5929 inset, 0px -4px 1px 0px #AA5929 inset;
}

.stat {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.statInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.premiumStat, .normalStat {
  width: 100%;
  height: 43px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  text-align: center;
  text-shadow: 0px 1px 0px #0000006e;
  -webkit-text-stroke-width: 0.5px;
  
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 13px;
}

.premiumStat {
  background-color: #21c5b3;
  border: 2px solid #a4fae8;
  -webkit-text-stroke-color: #1BB6EF;
}

.normalStat {
  background-color: #E59749;
  border: 2px solid #FFCC7C;
  -webkit-text-stroke-color: #803C1A;
}

.icon {
  margin-right: 5px;
  width: 12px;
  height: 14px;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 10px;
  overflow-y: scroll;
}

.infoText {
  text-shadow: 0px 1px 0px #0000007a;
  -webkit-text-stroke-width: .5px;
  -webkit-text-stroke-color: #803C1A;
  font-family: 'Marvin', sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.blocka {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 13px;
  border-radius: 12px;
  border: 2px solid #984C1F;
  opacity: var(--sds-size-stroke-border);
  background: #F6B867;
  box-shadow: -4px 0px 1px 0px #AA5929 inset, 4px 0px 1px 0px #AA5929 inset, 0px 4px 1px 0px #AA5929 inset, 0px -4px 1px 0px #AA5929 inset;
}

.subBlock {
  width: 142px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.premiumStata, .normalStata {
  width: 100%;
  height: 43px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  text-align: center;
  text-shadow: 0px 1px 0px #00000080;
  -webkit-text-stroke-width: .5px;
  font-size: 15px;
  font-weight: 400;
  line-height: 12px; /* 80% */
  letter-spacing: 0.4px;
}

.premiumStata {
  background-color: #21c5b3;
  border: 2px solid #a4fae8;
  -webkit-text-stroke-color: #1BB6EF;
}

.normalStata {
  background-color: #E59749;
  border: 2px solid #FFCC7C;
  -webkit-text-stroke-color: #803C1A;
}