.overlay {
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
}

.modal {
  position: relative;
  width: 346px;
  height: 315px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cross {
  z-index: 1;
  position: absolute;
  top: 18px;
  right: 23px;
  width: 40px;
  height: 40px;
}

.close {
  width: 40px;
  height: 40px;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 346px;
  height: 315px;
}

.content {
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 43px;
}

.boostItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.boostImgFrame {
  position: relative;
  width: 45px;
  height: 45px;
  background-color: #E59749;
  border: 2px solid #FFCC7C;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.level {
  position: absolute;
  top: -7px;
  left: -7px;

  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 2px solid #FFCC7C;
  background-color: #76B733;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 10px;
  font-weight: 400;
}

.boostText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-shadow: 0px 1px 0px #00000070;
  -webkit-text-stroke-width: .5px;
  -webkit-text-stroke-color: #803C1A;
  font-size: 17px;
  font-weight: 900;
}

.boostButton {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70px;
  height: 36px;
  border-radius: 10px;
  background-color: #FFCD00;
  box-shadow: 0px -3px 4.472px 0px #FF9C00 inset;
  filter: drop-shadow(0px 3px 5.656px rgba(93, 48, 19, 0.60));

  text-align: center;
  text-shadow: 0px 1px 0px #00000078;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #803C1A;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

.lvl {
  -webkit-text-stroke-width: .8px;
  -webkit-text-stroke-color: #AA5929;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.carrot {
  width: 15.882px;
  height: 18px;
}

.bdiamond {
  width: 60px;
  height: 60px;
}

.bstamina {
  width: 25px;
  height: 33px;
}

.bmeal {
  width: 33px;
  height: 26px;
}

.bmining {
  width: 35px;
  height: 27px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  z-index: 1;
  position: relative;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #A15424;
  font-family: "LilitaOne", sans-serif;
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 0;
}

.fontBack {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-text-stroke-width: 10px;
  -webkit-text-stroke-color: #FFBC00;

  font-family: "LilitaOne", sans-serif;
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 0;
}

.message {
  position: relative;
  margin-top: 15px;
  text-shadow: 0px 1px 0px #0000007e;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #AA5929;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 107.692% */
  letter-spacing: 0.4px;
}

.button {
  position: relative;
  margin-top: 30px;
  width: 81px;
  height: 36px;
  border-radius: 12px;
  background: #9EC604;
  box-shadow: 0px 3px 5.656px 0px rgba(93, 48, 19, 0.60), 0px -3px 4.472px 0px #64A200 inset;

  display: flex;
  justify-content: center;
  align-items: center;

  text-shadow: 0px 1px 0px #0000007d;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #803C1A;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
}