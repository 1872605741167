.Earn {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: #593218;
}

.cross {
  z-index: 1;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
}

.close {
  width: 40px;
  height: 40px;
}

.earnBg {
  position: absolute;
  width: 100%;
}

.balanceContainer {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hareBalance {
  margin-top: 25px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.balanceTitle {
  text-align: center;
  font-family: "LilitaOne", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.balanceValue {
  position: relative;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #A15424;
  font-family: "LilitaOne", sans-serif;
  font-size: 42px;
  font-weight: 400;
  letter-spacing: 0;
}

.fontBack {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-text-stroke-width: 10px;
  -webkit-text-stroke-color: #FFBC00;

  font-family: "LilitaOne", sans-serif;
  font-size: 42px;
  font-weight: 400;
  letter-spacing: 0;
}

.tasksContainer {
  position: relative;
  width: 92%;
  height: 70%;
  padding-top: 70px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 12px 12px 0px 0px;
  background-color: #B36430;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.taskBg {
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.category {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 15px;
  margin-bottom: 30px;
}

.categoryTitle {
  z-index: 1;
  text-shadow: 0px 2px 0px #00000071;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #AA5929;
  font-family: "LilitaOne", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 14px; /* 77.778% */
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-left: 20px;
}

.scroll {
  width: 112%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.taskSign {
  position: absolute;
  top: -40px;
  width: 90%;
  height: 80px;
  border-radius: 12px;
  border: 3px solid #984C1F;
      
  background: #AB5B2A;
    
  box-shadow: 0px 0px 50.1px 0px #AA5929 inset;
  
  padding: 10px 13px 10px 10px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
}

.signContainer {
  width: 100%;
  height: 100%;
  padding: 12px 15px;

  border-radius: 12px;
  border: 2px solid #984C1F;
  background: #F6B867;
  box-shadow: -4px 0px 1px 0px #AA5929 inset, 4px 0px 1px 0px #AA5929 inset, 0px 4px 1px 0px #AA5929 inset, 0px -4px 1px 0px #AA5929 inset;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.vintik, .metalVintik {
  width: 12px;
  height: 12px;
}

.signText {
  text-align: center;
  -webkit-text-stroke-width: 0.6px;
  -webkit-text-stroke-color: #AA5929;
  font-size: 15px;
  font-weight: 600;
}

.above {
  position: absolute;
  top: -23px;
  width: 144px;
  height: 40px;
  border-radius: 12px;

  border: 3px solid #C1BBB1;
  
  background: #AEABA6;
  
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 4px 0px #AEABA6 inset;
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.aboveText {
  text-align: center;
  text-shadow: 0px 2px 0px #54524f;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #827F7B;
  font-family: "LilitaOne", sans-serif;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}