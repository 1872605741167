.CarrotBalance {
  position: relative;
  width: 100%;
  height: 22px;
  border-radius: 12px;
  border: 3px solid #984C1F;
  background: #803C1A;  
  padding:  0 8px;
  
  display: flex;
  justify-content: end;
  align-items: center;
}

.value {
  text-align: right;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #803C1A;
  font-family: "LilitaOne", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.orangeValue {
  position: absolute;
  left: -11px;
  top: -11px;
}

.goldValue {
  position: absolute;
  left: -15px;
  top: -15px;
}

.diamondValue {
  position: absolute;
  left: -25px;
  top: -20px;
}