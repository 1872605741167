.MenuItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menuButton {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  border: 2px solid #F4AE5C;
  background: #AF5E2B;

  display: flex;
  justify-content: center;
  align-items: center;
}

.shadow {
  position: absolute;
  border-radius: 15px;
  z-index: 10;
  width: 100%;
  height: 100%;
  box-shadow: inset rgba(136, 68, 25, 0.6) 0 -1px 12px 0px;
}

.menuTitle {
  text-align: center;
  -webkit-text-stroke-width: 0.8px;
  -webkit-text-stroke-color: #AF5E2B;
  
  font-family: 'Marvin', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.playImg {
  width: 17.409px;
  height: 20.457px;
}

.earnImg {
  width: 26.712px;
  height: 30px;
}

.boostImg {
  width: 19px;
  margin-right: 3px;
}

.inviteImg {
  width: 33px;
  height: 30px;
}